<template>
	<div>
		<v-pagination
      light
			v-if="tamanho > 1"
			v-model="atualizar"
			:length="tamanho"
			:total-visible="7"
			:disabled="carregando"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "Paginacao",
	props: ["total", "elevation", "pgLimitInt", "carregando", "pg"],
	data: () => ({
		atualizar: 1,
	}),
	computed: {
		...mapState(["pgLimit"]),
		tamanho() {
			if (this.pgLimitInt) {
				return Math.ceil(this.total / this.pgLimitInt);
			} else {
				return Math.ceil(this.total / this.pgLimit);
			}
		},
	},
	watch: {
		atualizar(p) {
			this.$emit("atualizar", p - 1);
		},
	},
	updated() {
		let pg = this.pg || 0;
		this.atualizar = pg + 1;
	},
};
</script>

<style scoped>
</style>

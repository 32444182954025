<template>
	<v-row no-gutters>
    <v-card width="100%">
      <v-card-text>
        <v-row class="pa-3">
          <v-text-field
            clearable
            autofocus
            v-model="busca"
            label="Busca de Produto"
            placeholder="Informe o código ou descrição do produto"
            outlined
            dense
            hide-details
            @keydown.enter = listar(0)
          />
          <v-btn height="38px" class="primary ml-3" @click="listar(0)">
            Buscar
            <v-icon size="20" class="pl-2">mdi-magnify</v-icon>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="primary mt-3" width="100%">
      <v-card-title class="white--text" v-if="buscado">
        <v-col cols="12" class="pa-0">Resultados para "{{buscado}}"</v-col>
        <v-col v-if="!carregando" style="font-size: small" class="pa-0 mb-n3 mt-n1">{{produtos.total}} itens encontrados.</v-col>
      </v-card-title>
      <v-card-title class="white--text" v-else>
        Consulta de Produtos
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
        <v-data-table v-else
            :headers="[
                { text: 'Código', value: 'idsubproduto', align:'left', class: 'pl-5', sortable: false},
                { text: 'Descrição', value: 'descricao', align:'left',  sortable: false},
                { text: 'Quantidade Estoque CD', value: 'qtdestoque', align:'center', sortable: false},
                { text: 'Quantidade Reservada', value: 'qtdestoquereservada', align:'center', sortable: false},
                { text: 'Quantidade Disponível', value: 'qtdestoquedisponivel', align:'center', sortable: false},
            ]"
            :items="produtos.lista"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-left pl-5"> {{ item.idsubproduto }} </td>
              <td class="text-left"> {{ item.descricao }} </td>
              <td class="text-center"> <v-chip small style="width: 90px; justify-content:center"> <strong>{{ item.qtdestoque }}</strong> </v-chip></td>
              <td class="text-center"> <v-chip small style="width: 90px; justify-content:center"> <strong>{{ item.qtdestoquereservada }}</strong> </v-chip></td>
              <td class="text-center"> <v-chip small style="width: 90px; justify-content:center"> <strong>{{ item.qtdestoque - item.qtdestoquereservada }}</strong> </v-chip></td>
            </tr>
          </template>
        </v-data-table>
        <v-col class="text-center" cols="12" v-if="produtos.total>1">
          <Paginacao
              :total="produtos.total"
              @atualizar="listar"
              :pgLimitInt="pgLimit"
              :carregando="carregando"
              :pg="pgSelecionada"
          />
        </v-col>
      </v-card-text>
    </v-card>
	</v-row>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";
import Paginacao from "../Widgets/Paginacao.vue";

export default {
	name: "CdEstoque",
	components: {Paginacao},
	data: () => ({
    busca: '',
    carregando: false,
    carregandoSkeleton: false,
    produtos: { lista: [], total: 0 },
    pgLimit: 20,
    pgSelecionada: 0,
    buscado: "",
	}),
	computed: {
    ...mapState(["backendUrl", "pgLimit", "usuario"]),
  },
	methods: {
    listar(pg) {
      this.buscado = this.busca;
      this.pgSelecionada = pg;
      this.carregando = true;
      this.carregandoSkeleton = true;
      return axios
          .post(`${this.backendUrl}cd/reserva/produtos/listar`, {
            busca: this.busca,
            limit: this.pgLimit,
            offset: this.pgLimit * pg,
          })
          .then((res) => {
            this.produtos = res.data;
            this.carregando = false;
            this.carregandoSkeleton = false;
          })
          .catch(() => {
            this.carregandoSkeleton = false;
            this.produtos = { lista: [], total: 0 };
            this.carregando = false;
          });
    },
		async init() {},
	},
  // watch: {
  //   "busca": function () {
  //     this.listar(0);
  //   },
  // },
	created() {
		this.init();
	},
};
</script>